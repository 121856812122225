import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ConnectCoreModule, ConnectCoreModuleConfig } from "@aveva/connect-web-core";

import { TestRunsModule } from "./test-runs/test-runs.module";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { environment } from "../environments/environment";
import { SpectrumSmokeTestService } from "./services/spectrum-smoketest.service";

const cachePrefix = "spectrum.devops.smoketest.dashboard";

const coreModuleConfig: ConnectCoreModuleConfig = {
  authentication: {
    authority: environment.authority,
    clientId: environment.clientId,
    cachePrefix,
    singleSignOutUrl: environment.singleSignOutUrl,
  },
  authorization: {
    cachePrefix,
  },
  connectApi: {
    logging: environment.apis.logging,
    registry: environment.apis.registry,
    am: environment.apis.am,
    scm: environment.apis.scm,
    uam: environment.apis.uam,
    assets: environment.apis.assets,
  },
  components: {
    appHeaderLogoPath: "", // Path to a custom logo for your capability can be specified here.
    sideNavLogoPath: "/assets/imgs/AVEVA-Cloud_interimmark_rev01-01_black.svg",
    termsAndConditions: "https://sw.aveva.com/legal",
    connectLink: environment.connect,
  },
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ConnectCoreModule.forRoot(coreModuleConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TestRunsModule,
    AppRoutingModule,
  ],
  providers: [SpectrumSmokeTestService],
  bootstrap: [AppComponent],
})
export class AppModule {}
