import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { TestRunsComponent } from "./test-runs.component";

import { AccountResolverService } from "../resolvers/account-resolver.service";

import { AuthenticatedUserResolverService } from "@aveva/connect-web-core";
import { TestRunsGuard } from "./test-runs.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "testRuns",
    pathMatch: "full",
  },
  {
    path: "testRuns",
    component: TestRunsComponent,
    canActivate: [TestRunsGuard],
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TestRunsRoutingModule {}
