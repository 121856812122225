import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ConnectCoreModule } from "@aveva/connect-web-core";

import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";

import { TestRunsRoutingModule } from "./test-runs-routing.module";
import { TestRunsComponent } from "./test-runs.component";
import { AccountResolverService } from "../resolvers/account-resolver.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { TestRunsGuard } from "./test-runs.guard";
import { InternalUseDialogComponent } from "../dialogs/internal-use-dialog/internal-use-dialog.component";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BusyDirective } from "../directives/busy-directive/busy-directive";
import { BrowserModule } from "@angular/platform-browser";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
  imports: [
    CommonModule,
    TestRunsRoutingModule,
    ConnectCoreModule,
    MatButtonModule,
    MatTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatRippleModule,
    MatTooltipModule,
    BrowserModule,
    MatProgressSpinnerModule,
    BusyDirective,
    MatCheckboxModule,
  ],
  providers: [AccountResolverService, TestRunsGuard],
  declarations: [TestRunsComponent, InternalUseDialogComponent, ConfirmationDialogComponent],
})
export class TestRunsModule {}
