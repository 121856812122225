import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthenticationService } from "@aveva/connect-web-core";
import { from, Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

type ListResponse<T> = { items: Array<T> };

export type HttpOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
};

export type HttpGetStringOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  responseType: "text";
};

export class ApiService {
  public static getSpectrumApiUrl(service: string): string {
    const serviceName = environment.spectrum.stage === "prod" ? service : `${service}-${environment.spectrum.stage}`;

    return `${environment.spectrum.dataSourceUrl}/${serviceName}/v1`;
  }

  constructor(private readonly http: HttpClient, private readonly authService: AuthenticationService, private readonly baseUrl: string) {}

  getList<T>(path: string, httpOptions?: HttpOptions): Observable<Array<T>> {
    return this.get<ListResponse<T>>(path, httpOptions).pipe(map((data) => data.items));
  }

  get<T>(path: string, httpOptions?: HttpOptions): Observable<T> {
    return this.buildHeaders().pipe(
      mergeMap((headers) => this.http.get<T>(`${this.baseUrl}/${path}`, { responseType: "json", headers, ...httpOptions }))
    );
  }

  getString(path: string, httpOptions?: HttpGetStringOptions): Observable<string> {
    return this.buildHeaders().pipe(
      mergeMap((headers) => this.http.get(`${this.baseUrl}/${path}`, { responseType: "text", headers, ...httpOptions }))
    );
  }

  post<T, Tbody>(path: string, body: Tbody, httpOptions?: HttpOptions): Observable<T> {
    return this.buildHeaders().pipe(
      mergeMap((headers) => this.http.post<T>(`${this.baseUrl}/${path}`, body, { responseType: "json", headers, ...httpOptions }))
    );
  }

  private buildHeaders(): Observable<HttpHeaders> {
    return from(this.authService.getToken()).pipe(
      map(
        (token) =>
          new HttpHeaders({
            authorization: `Bearer ${token}`,
          })
      )
    );
  }
}
